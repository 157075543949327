import { addDays, startOfDay, endOfDay } from 'date-fns';
import { StoreInfoObject } from '../interface/StoreInterface';
import { randomNumber } from '../Others';

export const createStoreTitle = (teamName: string): string => {
    return `${teamName} Store`;
};

export const getDemoStoreInfoObject = (teamName: string, teamEmail: string): StoreInfoObject => {
    return {
        title: createStoreTitle(teamName),
        address: '',
        storeActivate: true,
        productsActivate: true,
        ticketsActivate: true,
        formsActivate: true,
        productNotificationEmails: [{ id: randomNumber(), email: teamEmail }],
        ticketNotificationEmails: [],
        scheduleStartTime: startOfDay(addDays(new Date(), 0)),
        scheduleEndTime: endOfDay(addDays(new Date(), 300)),
    };
};
