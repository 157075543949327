import { getCategories } from '../components/portal/marketplace/MarketplaceData';
import { SportsType } from './enums/SportsType';
import { WebsiteType } from './enums/WebsiteType';
import { optimXDevelopment } from './Others';

export const ANALYTICS_NAVLINKS = () => {
    return [
        { title: 'Website  Analytics', link: '/portal/analytics/website' },
        { title: 'Store Analytics', link: '/portal/analytics/store' },
    ];
};

export const WEBSITE_NAVLINKS = (websiteType: WebsiteType) => {
    if (websiteType === WebsiteType.STORE) {
        return [
            { title: 'Info', link: '/portal/website/info' },
            { title: 'Design', link: '/portal/website/design' },
            { title: 'Images', link: '/portal/website/images' },
            { title: 'Google Info(SEO)', link: '/portal/website/seo' },
        ];
    }
    return [
        { title: 'Info', link: '/portal/website/info' },
        { title: 'Store Info', link: '/portal/website/store' },
        { title: 'Design', link: '/portal/website/design' },
        { title: 'Images', link: '/portal/website/images' },
        { title: 'Google Info(SEO)', link: '/portal/website/seo' },
        { title: 'Socials', link: '/portal/website/socials' },
    ];
};

export const FORMS_NAVLINKS = () => {
    return [
        { title: 'Player Dues', link: '/portal/forms/playerdues' },
        { title: 'Custom Forms', link: '/portal/forms/customforms' },
        { title: 'Pre-Developed Forms', link: '/portal/forms/predevelopedforms' },
        { title: 'Submissions', link: '/portal/forms/formssubmitted' },
        { title: 'Form Emails', link: '/portal/forms/emails' },
    ];
};

export const EVENTS_NAVLINKS = () => {
    return [
        { title: 'Events', link: '/portal/events/events' },
        { title: 'Practice', link: '/portal/events/practice' },
        { title: 'Events & Practice', link: '/portal/events/eventsandpractice' },
    ];
};

export const ARTICLES_NAVLINKS = () => {
    return [
        { title: 'Articles', link: '/portal/articles/articles' },
        { title: 'Event Articles', link: '/portal/articles/events' },
        { title: 'Game Articles', link: '/portal/articles/games' },
        { title: 'All Articles', link: '/portal/articles/all' },
    ];
};

export const GALLERIES_NAVLINKS = () => {
    return [
        { title: 'Galleries', link: '/portal/galleries/galleries' },
        { title: 'Article Galleries', link: '/portal/galleries/articles' },
        { title: 'Event Galleries', link: '/portal/galleries/events' },
        { title: 'Game Galleries', link: '/portal/galleries/games' },
        { title: 'All Galleries', link: '/portal/galleries/all' },
    ];
};

export const ROSTER_NAVLINKS = () => {
    return [
        { title: 'Roster', link: '/portal/roster/roster' },
        { title: 'Alumni', link: '/portal/roster/alumni' },
    ];
};

export const SETTINGS_NAVLINKS = () => {
    if (optimXDevelopment) {
        return [
            { title: 'Admin', link: '/portal/settings/admin' },
            { title: 'Subscription & Account', link: '/portal/settings/account' },
            { title: 'Communications', link: '/portal/settings/communications' },
            { title: 'Agreements & Policies', link: '/portal/settings/agreements' },
        ];
    }
    return [
        { title: 'Admin', link: '/portal/settings/admin' },
        { title: 'Subscription & Account', link: '/portal/settings/account' },
        { title: 'Communications', link: '/portal/settings/communications' },
        { title: 'Agreements & Policies', link: '/portal/settings/agreements' },
    ];
};

export const CUSTOM_CONTENTS_NAVLINKS = () => {
    return [
        { title: 'Custom Pages', link: '/portal/custom/pages' },
        { title: 'Custom Forms', link: '/portal/custom/forms' },
    ];
};

export const HELP_NAVLINKS = (websiteType: WebsiteType) => {
    if (websiteType === WebsiteType.STORE) {
        return [
            { title: 'Schedule Call', link: '/portal/tutorials/schedule' },
            { title: 'Questions?', link: '/portal/tutorials/questions' },
            { title: 'Reviews', link: '/portal/tutorials/reviews' },
        ];
    }
    return [
        { title: 'Schedule Call', link: '/portal/tutorials/schedule' },
        { title: 'Questions?', link: '/portal/tutorials/questions' },
        { title: 'Tutorials', link: '/portal/tutorials/tutorials' },
        { title: 'Reviews', link: '/portal/tutorials/reviews' },
    ];
};

export const STORE_NAVLINKS = () => {
    return [
        { title: 'Overview', link: '/portal/store/overview' },
        { title: 'Settings', link: '/portal/store/settings' },
        { title: 'Schedule', link: '/portal/store/schedule' },
        { title: 'Products', link: '/portal/store/products' },
        { title: 'Tickets', link: '/portal/store/tickets' },
        { title: 'Forms', link: '/portal/store/forms' },
        { title: 'Charges', link: '/portal/store/charges' },
        // { title: 'Customers', link: '/portal/store/customers' },
        // { title: 'Balances', link: '/portal/store/balances' },
        // { title: 'Refunds', link: '/portal/store/refunds' },
        { title: 'Disputes', link: '/portal/store/disputes' },
        { title: 'Stripe Account', link: '/portal/store/stripeaccount' },
    ];
};

export const MARKETPLACE_NAVLINKS = (sportsType: SportsType) => {
    return [{ title: 'Vendors, Brands', link: '/portal/marketplace/brands' }, ...getCategories(sportsType)];
};
